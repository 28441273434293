import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { RejectReason } from "../interface/common.interface";
import { firstValueFrom } from "rxjs";
import { AlertService } from "../service/alert.service";

@Injectable({
  providedIn: "root"
})
export class RejectReasonDataService {
  private basePath: string = `${environment.url}/api/RejectReason/`;

  constructor(private http: HttpClient, private alertService: AlertService) {}

  async getRejectType(): Promise<RejectReason[]> {
    let response: RejectReason[];

    try {
      response = await firstValueFrom(
        this.http.get<RejectReason[]>(`${this.basePath}GetSelection`)
      );
    } catch (error) {
      this.alertService.openSnackBar("Failed to retrieve reject type");
      throw new Error();
    }

    return response;
  }
}
