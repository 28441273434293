import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, mergeMap, Observable, of } from "rxjs";
import { AlertService } from "src/app/share/service/alert.service";
import { AuthService } from "src/app/share/service/auth.service";
import { LoaderService } from "src/app/share/service/loader.service";
import { ErrorCode } from "../enums";

@Injectable()
export class RestInterceptor implements HttpInterceptor {
  token: string;

  constructor(
    private loaderService: LoaderService,
    private alertService: AlertService,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.token = this.authService.getToken();

    this.loaderService.show(req.url);

    if (this.token) {
      req = req.clone({
        headers: req.headers.append("Authorization", `Bearer ${this.token}`)
      });
    }

    return next
      .handle(req)
      .pipe(catchError(this.interceptErrorResponse.bind(this)))
      .pipe(mergeMap(this.interceptResponse.bind(this)));
  }

  private interceptResponse(event: HttpEvent<any>): Observable<any> {
    if (event instanceof HttpResponse) {
      this.loaderService.hide(event.url);

      const token = event.headers.get("token");

      if (token) {
        this.token = token;
      }
    }

    return of(event);
  }

  private interceptErrorResponse(event: HttpEvent<any>): Observable<any> {
    if (event instanceof HttpErrorResponse) {
      this.loaderService.hide(event.url);

      switch (event.status) {
        case 0:
          this.alertService.openSnackBar("Unable to connect server.");
          break;

        case 400:
          this.alertService.openSnackBar(event.error);
          break;

        case 401:
          this.authService.logout();
          break;

        case 404:
          const urlPrefix = event.url.split("/");
          if (urlPrefix.includes("GetEwpDetailsByPolicyId")) return of(event);

          this.alertService.openSnackBar("Resource not found.");
          return of(
            new HttpResponse({
              body: {
                errorCode: ErrorCode.FAIL,
                errorDesc: "Resource not found."
              }
            })
          );

        case 500:
          this.alertService.openSnackBar("Internal Server Error.");
          break;
      }
    }

    return of(event);
  }
}
