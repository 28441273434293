import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CountBarComponent } from "./count-bar/count-bar.component";
import { MaterialModule } from "../../material-module";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { NgxMaskModule } from "ngx-mask";
import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";
import { SkeletonLoaderComponent } from "./skeleton-loader/skeleton-loader.component";
import { ImageDialogComponent } from "./image-upload/image-dialog/image-dialog.component";
import { RejectQuoteComponent } from "./reject-quote/reject-quote.component";

@NgModule({
  declarations: [
    CountBarComponent,
    SkeletonLoaderComponent,
    ImageDialogComponent,
    RejectQuoteComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    NgxSkeletonLoaderModule,
    FlexLayoutModule,
    MatBottomSheetModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    NgxSkeletonLoaderModule,
    NgxMaskModule,
    NgxMatIntlTelInputModule
  ],
  exports: [CountBarComponent, SkeletonLoaderComponent, RejectQuoteComponent]
})
export class SharedComponentModule {}
