import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ICountbarData } from "../../interface/common.interface";

@Component({
  selector: "app-count-bar",
  templateUrl: "./count-bar.component.html",
  styleUrls: ["./count-bar.component.scss"]
})
export class CountBarComponent implements OnChanges {
  @Input() receivedData: ICountbarData;

  themeOptions: ThemeOptions;

  constructor() {
    this.themeOptions = {
      count: 1,
      theme: {
        height: "30px",
        "border-radius": "15px",
        "animation-duration": "1s"
      }
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.receivedData = changes.receivedData.currentValue;
  }

  getPercentage(val: number) {
    if (this.receivedData.name === "Roadtax") {
      return (
        val /
        (this.receivedData.totalQuotation + this.receivedData.previousDays)
      );
    }
    return val / this.receivedData.totalQuotation;
  }
}

interface ThemeOptions {
  count: number;
  theme: any;
}
