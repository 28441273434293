export enum PackageCode {
  DeviceCare = "MxDevice_B",
  DeviceCarePlus = "MxDevice_P",
  SafeDevice = "MxDevice_S"
}

export const PACKAGE_CODE_LABEL_MAP = {
  [PackageCode.DeviceCare]: "Device Care",
  [PackageCode.DeviceCarePlus]: "Device Care Plus",
  [PackageCode.SafeDevice]: "Safe Device"
}

export function getPackageCodeLabel(packageCode: PackageCode): string {
  return PACKAGE_CODE_LABEL_MAP[packageCode] || "";
}
