import { Injectable } from "@angular/core";
import { UserDataService } from "../data-service/user.data.service";
import { UserProfileInterface } from "../interface/user.interface";

@Injectable({
  providedIn: "root"
})
export class UserService {
  user: UserProfileInterface;

  constructor(private userDataService: UserDataService) {}

  async updateProfile(): Promise<void> {
    this.user = await this.userDataService.getProfile();
  }
}
