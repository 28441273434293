import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root"
})
export class LoaderService {
  loaderArray: string[] = [];
  isLoading: boolean = false;

  // Specifc Url to not trigger ngxSpinner
  private readonly specificUrl: string[] = [
    "GetListing",
    "GetIQQuotationListing",
    "GetRoadTaxListing",
    "GetPolicyListing",
    "GetPolicyIssueListing",
    "GetBulkQuotationListing",
    "GetEndorsementListing",
    "GetProductivityReport"
  ];

  constructor(private ngxSpinnerService: NgxSpinnerService) {}

  show(api: string): void {
    this.loaderArray.push(api.trim());
    this.isLoading = true;

    if (this.getUrlPrefix(api)) return;
    this.ngxSpinnerService.show();
  }

  hide(api: string): void {
    this.loaderArray = this.loaderArray.filter((obj) => obj !== api);

    if (this.loaderArray.length < 1) {
      this.isLoading = false;
    }

    if (this.getUrlPrefix(api)) return;
    this.ngxSpinnerService.hide();
  }

  getUrlPrefix(api: string): boolean {
    let url = api.split("/");

    return this.specificUrl.some((key) =>
      url.some((key2) => key2.includes(key))
    );
  }
}
