import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { Response, VehicleInterface } from "../../interface/common.interface";
import {
  MotorClonedQuotationInterface,
  MotorQuotationChangeOwnershipDetailInterface,
  MotorQuotationDetailInterface,
  MotorQuotationLinkListingInterface,
  MotorQuotationListingInterface,
  MotorQuotationNCDInfo,
  MotorQuotationPolicyDetailListingInterface,
  MotorQuotationPolicyInterface,
  MotorQuotationPolicyListingInterface,
  MotorQuotationUpdateNcd,
  MotorQuotationVehicleInfo
} from "../../interface/motor/motor-quotation.interface";
import { firstValueFrom } from "rxjs";
import { ErrorCode } from "src/app/core/enums";
import { AlertService } from "../../service/alert.service";

@Injectable({
  providedIn: "root"
})
export class MotorQuotationDataService {
  private basePath: string = `${environment.url}/api/Motor/MotorQuotation/`;

  constructor(private http: HttpClient, private alertService: AlertService) {}

  async getYesterdayCount(): Promise<number> {
    let response: number;

    response = await firstValueFrom(
      this.http.get<number>(`${this.basePath}GetYesterdayCount`)
    );

    // [24/12/2024] - Reason for such checking is because Method not returning default errorCode & errorDesc else will be more straightforward
    if (response === null || response === undefined) {
      this.alertService.openSnackBar("Failed to retreive yesterday count");
      throw response;
    }

    return response;
  }

  async getListing(
    request: MotorQuotationRequest
  ): Promise<MotorQuotationResponse> {
    let response: MotorQuotationResponse;

    response = await firstValueFrom(
      this.http.post<MotorQuotationResponse>(
        `${this.basePath}GetListing`,
        request
      )
    );

    if (response.errorCode === ErrorCode.FAIL) {
      throw new Error();
    }

    return response;
  }

  getIQQuotationListing(request: MotorQuotationRequest) {
    return this.http.post<MotorQuotationResponse>(
      `${this.basePath}GetIQQuotationListing`,
      request
    );
  }

  async getDetailById(id: string): Promise<MotorQuotationDetailInterface> {
    let response: MotorQuotationDetailInterface;

    response = await firstValueFrom(
      this.http.get<MotorQuotationDetailInterface>(
        `${this.basePath}GetDetailById/${id}`
      )
    );

    if (response.errorCode === ErrorCode.FAIL) {
      throw new Error();
    }

    return response;
  }

  async getChangeOwnershipDetail(
    id: string
  ): Promise<MotorQuotationChangeOwnershipDetailInterface> {
    let response: MotorQuotationChangeOwnershipDetailInterface;

    response = await firstValueFrom(
      this.http.get<MotorQuotationChangeOwnershipDetailInterface>(
        `${this.basePath}GetChangeOwnershipDetail/${id}`
      )
    );

    if (response.errorCode === ErrorCode.FAIL) {
      throw new Error();
    }

    return response;
  }

  // Note:
  // Reject Type Id (from FE) is Reject Reason Id (BE)
  // Reject Reason Id (from FE) is Reject Remark (BE)
  // Reject Remark (from FE) is Reject comment (BE)
  // isInstantQuotation (from FE) is indicate it's for instant quotation (BE)
  async reject(request: RejectQuoteRequest): Promise<any> {
    let response: any;

    try {
      response = await firstValueFrom(
        this.http.post(`${this.basePath}Reject/${request.id}`, request)
      );
      this.alertService.openSnackBar("Successfully Rejected");
    } catch (error) {
      this.alertService.openSnackBar("Reject Unsuccessful");
      throw new Error();
    }

    return response;
  }

  getQuotationPolicyById(id: string) {
    return this.http.get<MotorQuotationPolicyInterface>(
      `${this.basePath}GetQuotationPolicyById/${id}`
    );
  }

  getCarInfo(vehicleNo: string, identityNo: string) {
    return this.http.post<{
      vehicleInfo: MotorQuotationVehicleInfo;
      seat: number;
      typeOfVehicle: string;
      ncdInfo: MotorQuotationNCDInfo;
    }>(`${this.basePath}GetVehicleInfo`, {
      vehicleNo,
      identityNo
    });
  }

  editVehicle(id: string, val: any) {
    return this.http.post(`${this.basePath}EditVehicle/${id}`, val);
  }

  updateQuotationNcd(id: string, val: MotorQuotationUpdateNcd) {
    return this.http.post(`${this.basePath}UpdateQuotationNCD/${id}`, val);
  }

  editCustomer(id: string, val: any) {
    return this.http.post(`${this.basePath}EditCustomer/${id}`, val);
  }

  editNCD(id: string, previousVehicleNo: string, ncd: number) {
    return this.http.post(`${this.basePath}UpdateNCD/${id}`, {
      previousVehicleNo,
      ncd
    });
  }

  async getVehicleInfo(
    request: GetVehicleInfoRequest
  ): Promise<VehicleInterface> {
    let response: VehicleInterface;

    response = await firstValueFrom(
      this.http.post<VehicleInterface>(
        `${this.basePath}getVehicleInfo`,
        request
      )
    );

    if (response.errorCode === ErrorCode.FAIL) {
      throw new Error();
    }

    if (!response.vehicleInfo.isFound) {
      this.alertService.openSnackBar(response.vehicleInfo.errorMsg);
    }

    if (!response.ncdInfo.isFound) {
      this.alertService.openSnackBar(response.ncdInfo.errorMsg);
    }

    return response;
  }

  async add(request: any): Promise<void> {
    try {
      await firstValueFrom(this.http.post(`${this.basePath}Add`, request));

      this.alertService.openSnackBar("Successful Added");
      window.history.back();
    } catch (error) {
      this.alertService.openSnackBar("Add Unsuccessful");
    }
  }

  approve(id: string, val: any) {
    return this.http.post(`${this.basePath}Approve/${id}`, val);
  }

  getPolicyListing(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    motorPartnerIds: string[],
    motorProductIds: string[],
    statusList: string[],
    isTransferOwnership: boolean,
    startDate?: Date,
    endDate?: Date
  ) {
    return this.http.post<{
      count: any;
      data: MotorQuotationPolicyListingInterface[];
    }>(`${this.basePath}GetPolicyListing`, {
      limit,
      offset,
      text,
      columnIndex,
      sortDirection,
      motorPartnerIds,
      motorProductIds,
      isTransferOwnership,
      statusList,
      startDate,
      endDate
    });
  }

  getPolicyListingExcel(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    motorPartnerIds: string[],
    motorProductIds: string[],
    statusList: string[],
    isTransferOwnership: boolean,
    startDate?: Date,
    endDate?: Date
  ) {
    return this.http.post(
      `${this.basePath}GetPolicyListingExcel`,
      {
        limit,
        offset,
        text,
        columnIndex,
        sortDirection,
        motorPartnerIds,
        motorProductIds,
        statusList,
        isTransferOwnership,
        startDate,
        endDate
      },
      {
        responseType: "blob"
      }
    );
  }

  editRoadTax(id: string, price: number) {
    return this.http.get(`${this.basePath}EditRoadTax/${id}?price=${price}`);
  }

  getPolicyDetailListingById(id: string) {
    return this.http.get<MotorQuotationPolicyDetailListingInterface[]>(
      `${this.basePath}GetPolicyDetailListingByRequestId/${id}`
    );
  }

  getLink(id: string) {
    return this.http.get<MotorQuotationLinkListingInterface[]>(
      `${this.basePath}getLink/${id}`
    );
  }

  refreshLink(id: string) {
    return this.http.get(`${this.basePath}RefreshLink/${id}`);
  }

  async getClonedQuotationValue(
    id: string
  ): Promise<MotorClonedQuotationInterface> {
    let response: MotorClonedQuotationInterface;

    response = await firstValueFrom(
      this.http.get<MotorClonedQuotationInterface>(
        `${this.basePath}GetClonedQuotationValue/${id}`
      )
    );

    if (response?.errorCode === ErrorCode.FAIL) {
      throw new Error();
    }

    return response;
  }

  SendQuotationEmail(id: string) {
    return this.http.get(`${this.basePath}SendQuotationEmail/${id}`);
  }

  getPAndOVehicleInfo(id: string) {
    return this.http.post(`${this.basePath}GetPAndOVehicleInfo/${id}`, null);
  }

  getBulkQuotationListing(request: MotorQuotationRequest) {
    return this.http.post<{
      count: any;
      data: any[];
    }>(`${this.basePath}GetBulkQuotationListing`, request);
  }

  uploadBulkQuotationFile(id: string, file: any) {
    let fd = new FormData();

    fd.append(`File`, file);
    fd.append(`PartnerId`, id);
    return this.http.post(`${this.basePath}UploadBulkQuotationExcel`, fd, {
      responseType: "blob"
    });
  }

  downloadTemplate() {
    return this.http.get(
      `${this.basePath}DownloadPolicyBulkCancelExcelTemplate`,
      {
        responseType: "blob"
      }
    );
  }

  getInstantQuotation(id: string) {
    return this.http.get(`${this.basePath}GetD2CInstantQuotationById/${id}`);
  }

  async onHoldQuotation(id: string): Promise<any> {
    let response: any;

    try {
      response = await firstValueFrom(
        this.http.post<any>(`${this.basePath}OnHoldQuotation/${id}`, null)
      );

      this.alertService.openSnackBar("Quotation successfully on hold.");
    } catch (error) {
      this.alertService.openSnackBar("Quotation on hold unsuccessful");
      throw new Error();
    }

    return response;
  }
}

export interface MotorQuotationRequest {
  limit: number;
  offset: number;
  text: string;
  columnIndex: number;
  sortDirection: string;
  motorPartnerIds: string[];
  motorProductIds: string[];
  isTransferOwnership: boolean;
  status?: string;
  statusList?: string[];
  startDate?: Date;
  endDate?: Date;
}

interface MotorQuotationResponse extends Response {
  count: number;
  data: MotorQuotationListingInterface[];
  before6Count: number;
  after6Count: number;
}

export interface GetVehicleInfoRequest {
  vehicleNo: string;
  identityNo: string;
}

export interface RejectQuoteRequest {
  id: string;
  rejectReasonId: string;
  rejectRemark: string;
  comment: string;
  isInstantQuotation?: boolean;
}
