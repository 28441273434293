import { ErrorCode } from "src/app/core/enums";

export interface CommonSelectionInterface {
  id?: string; // temporary put it optional
  name: string;
  type?: string;
}

export interface NavMenuInterface {
  tabUrl: string;
  levelOneId: string;
  tabTitle: string;
  tabIcon: string;
  levelTwo: NavMenuLevelTwoInterface[];
  customClass?: string;
}

export interface NavMenuLevelTwoInterface {
  url: string;
  icon: string;
  title: string;
  levelTwoId: string;
}

export interface VehicleInfoInterface {
  nvic: string;
  variant: string;
  transmission: string;
  errorMsg?: string;
  isFound: boolean;
  vehicleNo: string;
  engineNo: string;
  chassisNo: string;
  make: string;
  model: string;
  year: number;
  engineCC: number;
}

export interface NcdInfoInterface {
  errorMsg?: string;
  isFound: boolean;
  expiredDateString: string;
  expiredDate: Date;
  ncd: number;
}

export interface VehicleInterface extends Response {
  vehicleInfo: VehicleInfoInterface;
  ncdInfo: NcdInfoInterface;
  seat?: number;
  typeOfVehicle: string;
}

export interface MotorDocumentInterface {
  id: string;
  fileName: string;
  contentType: string;
  createdDate: Date;
  createdBy?: string;
  refType?: string;
  fileSize?: number;
  base64?: any;
  isActive?: boolean;
  modifyDate?: Date;
}

export interface PSStaff {
  staffId: string;
  name: string;
  email: string;
  role: string;
}

export interface InsurerDocs {
  documentId: string;
  file: string;
}

export interface PSStaff {
  staffId: string;
  name: string;
  email: string;
  role: string;
}

export interface InsurerDocs {
  documentId: string;
  file: string;
}

export interface RejectReason {
  id: string;
  name: string;
  createdDate: Date;
  modifyDate: Date;
  isActive: boolean;
  hideForSelection: boolean;
}

export interface ICountbarData {
  totalQuotation: number;
  before6: number;
  after6: number;
  previousDays?: number;
  filterSelected?: string;
  name: string;
}

export interface FieldData {
  codeLabel: string;
  codeValue: any;
}

export interface IMatSelectOption {
  id: string;
  label: string;
  isDisabled?: boolean;
}

export interface Response {
  errorCode: `${ErrorCode}`;
  errorDesc: string;
}
