import { Component, Inject, Output, EventEmitter } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { AlertService } from "src/app/share/service/alert.service";
import {
  StatusTrackerModel,
  UploadRecord
} from "../../_interfaces/promoCode.interface";
import { PromoCodeMotorService } from "../../motor/services/promo-code-motor.service";
import { PromoCodeDealerService } from "../../dealer/services/promo-code-dealer.service";

@Component({
  selector: "app-status-dialog",
  templateUrl: "./status-dialog.component.html",
  styleUrls: ["./status-dialog.component.scss"]
})
export class StatusDialogComponent {
  @Output() confirm = new EventEmitter<any>();
  list: UploadRecord[] = [];

  sortIndex: number = 0;
  offset: number = 1;
  length = 0;
  sortDirection: "asc" | "desc" = "desc";
  limit: number = 50;
  displayedColumns: string[] = ["uploadDate", "fileName", "status", "uploadBy"];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StatusTrackerModel,
    private promoCodeMotorService: PromoCodeMotorService,
    private promoCodeDealerService: PromoCodeDealerService,
    private alertService: AlertService
  ) {
    this.getData();
  }

  onConfirm() {
    this.confirm.emit(true);
  }

  sortChange(sortState: Sort) {
    this.sortDirection = null;
    this.sortIndex = -1;
    if (sortState.direction) {
      this.sortDirection = sortState.direction;
      this.sortIndex = this.displayedColumns.findIndex(
        (x) => x == sortState.active
      );
    }
    if (this.list.length == 0) return;

    this.getData();
  }

  pageChange(e: PageEvent) {
    this.offset = e.pageIndex + 1;
    this.limit = e.pageSize;
    this.getData();
  }

  private getData() {
    this.list = [];
    this.length = 0;
    let serviceType;

    if (this.data.type === "motor") {
      serviceType = this.promoCodeMotorService;
    } else {
      serviceType = this.promoCodeDealerService;
    }

    serviceType.getUploadListing(this.sortIndex, this.sortDirection).subscribe({
      next: (x) => {
        this.list = x.data;
        this.length = x.count;
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      }
    });
  }
}
