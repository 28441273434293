import { Component, OnDestroy, OnInit } from "@angular/core";
import { catchError, takeUntil, tap } from "rxjs/operators";
import * as moment from "moment";
import { triggerFileDownload } from "../../helpers/downloadFile.helper";
import { AlertService } from "../../service/alert.service";
import { ECommerceDataService } from "../../data-service/e-commerce/e-commerce.data.service";
import { Subject, throwError } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-policy-cancellation-upload",
  templateUrl: "./policy-cancellation-upload.component.html",
  styleUrls: ["./policy-cancellation-upload.component.scss"]
})
export class PolicyCancellationUploadComponent implements OnInit, OnDestroy {
  fileToUpload: File;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<PolicyCancellationUploadComponent>,
    private alertService: AlertService,
    private ecommerceDataService: ECommerceDataService
  ) {}

  ngOnInit(): void {}

  uploadFile(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.fileToUpload = inputElement.files[0];

    inputElement.value = null;
  }

  submitFile() {
    if (this.fileToUpload) {
      this.dialogRef.close(this.fileToUpload);
    }
  }

  removeSelectedFile() {
    this.fileToUpload = null;
  }

  downloadTemplate() {
    this.ecommerceDataService
      .downloadPolicyBulkCancelExcelTemplate()
      .pipe(
        tap((result: Blob) => {
          const fileName = `Policy Cancellation Template ${moment().format(
            "DD/MM/yyyy"
          )}`;
          triggerFileDownload(result, fileName);
        }),
        catchError((err) => {
          const message = "Could not download the template";
          this.alertService.openSnackBar(message);
          return throwError(err);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
