<div class="dialog-header">Reject Quote</div>
<mat-divider></mat-divider>
<div class="dialog-content">
  <div class="title">
    Please state the reason of rejecting <span>Quotation</span> below.
  </div>

  <form [formGroup]="form">
    <mat-form-field class="full-width">
      <mat-label>Reject Type</mat-label>
      <mat-select required formControlName="rejectTypeId">
        <mat-option *ngFor="let item of rejectTypeList" [value]="item.id">{{
          item.name
        }}</mat-option>
      </mat-select>
      <mat-error>Reject type is required</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="rejectReasonList.length">
      <mat-label>Reject Reason</mat-label>
      <mat-select required formControlName="rejectReasonId">
        <mat-option *ngFor="let item of rejectReasonList" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
      <mat-error>Reject Reason is required</mat-error>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="showRemark">
      <mat-label>Remark</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="4"
        cdkAutosizeMaxRows="6"
        formControlName="remark"
      ></textarea>
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="rejectReasonChildList.length">
      <mat-label>Invalid / Incorrect Info Reason</mat-label>
      <mat-select required formControlName="remark">
        <mat-option *ngFor="let item of rejectReasonChildList" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
      <mat-error>Invalid / Incorrect Info Reason is required</mat-error>
    </mat-form-field>
  </form>
</div>

<div class="dialog-footer">
  <button type="button" mat-button [mat-dialog-close]="false">Cancel</button>
  <button
    type="submit"
    [disabled]="form.invalid"
    mat-flat-button
    class="btn btn-primary"
    (click)="save()"
  >
    Reject
  </button>
</div>
