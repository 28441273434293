import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { getClaimStatusLabel } from "../../constants/maxis/claim-status";
import { getPackageCodeLabel } from "../../constants/maxis/package-code";
import { CommonSelectionInterface } from "../../interface/common.interface";
import {
  EcomPoliciesListing,
  EcomPolicyClaimListing,
  EcomPolicyDetails,
  MaxisPoliciesListing,
  MaxisPolicyClaimListing,
  MaxisPolicyDetail
} from "../../interface/ecommerce/ecommerce.interface";

@Injectable({
  providedIn: "root"
})
export class ECommerceDataService {
  private basePathPolicy: string = `${environment.url}/api/ShopeePolicy/`;
  private basePathDocument: string = `${environment.url}/api/ShopeeDocument/`;
  private basePathMaxisPolicy = `${environment.url}/api/MaxisPolicy/`;
  private basePathMaxisClaim = `${environment.url}/api/MaxisClaim/`;

  constructor(private http: HttpClient) {}

  // Shopee Policy
  getListing(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    startDate: Date,
    endDate: Date,
    claimStatusList?: string[],
    policyStatusList?: string[],
    groupPolicyNoList?: string[]
  ) {
    return this.http.post<{
      count: number;
      data: EcomPoliciesListing[];
    }>(`${this.basePathPolicy}GetListing`, {
      limit,
      offset,
      text,
      columnIndex,
      sortDirection,
      claimStatusList,
      policyStatusList,
      groupPolicyNoList,
      startDate,
      endDate
    });
  }

  getClaimTypeList(): CommonSelectionInterface[] {
    return [
      {
        id: "All Risks - Accidental Damage/Fire/Flood",
        name: "All Risks - Accidental Damage/Fire/Flood"
      },
      { id: "Lightning Damage", name: "Lightning Damage" },
      { id: "Loss due to Theft/Burglary", name: "Loss due to Theft/Burglary" },
      { id: "Wear and Tear", name: "Wear and Tear" }
    ];
  }

  getClaimStatusList(): CommonSelectionInterface[] {
    return [
      { id: "N", name: "New" },
      { id: "P", name: "Processing" },
      { id: "OR", name: "Ops Rejected" },
      { id: "PIA", name: "Pending Insurer Acceptance" },
      { id: "PIE", name: "Pending Insurer Evaluation" },
      { id: "IA", name: "Insurer Approved" },
      { id: "IR", name: "Insurer Rejected" },
      { id: "IW", name: "Insurer Withdrawn" },
      { id: "PTA", name: "Pending TPA Assessment" },
      { id: "PRR", name: "Pending Repair Replace" },
      { id: "C", name: "Completed" }
    ];
  }

  getPolicyStatusList(): CommonSelectionInterface[] {
    return [
      { id: "Active", name: "Active" },
      { id: "Cancelled", name: "Cancelled" },
      { id: "Lapsed", name: "Lapsed" }
    ];
  }

  deactivatePolicy(id: string, values: any) {
    return this.http.post(
      `${this.basePathPolicy}DeactivatePolicy/${id}`,
      values
    );
  }

  getPolicyClaimDetail(id: string) {
    return this.http.get<EcomPolicyDetails>(
      `${this.basePathPolicy}GetPolicyClaimDetail/${id}`
    );
  }

  getPolicyClaimListing(
    id: string,
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    claimStatusList: string[],
    claimTypeList: string[]
  ) {
    return this.http.post<{ count: number; data: EcomPolicyClaimListing[] }>(
      `${this.basePathPolicy}GetPolicyClaimListing/${id}`,
      {
        limit,
        offset,
        text,
        columnIndex,
        sortDirection,
        claimTypeList,
        claimStatusList
      }
    );
  }

  getPolicyListingExcel(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    claimStatusList: string[],
    policyStatusList: string[],
    groupPolicyNoList: string[],
    startDate: Date,
    endDate: Date
  ) {
    return this.http.post(
      `${this.basePathPolicy}GetPolicyListingExcel`,
      {
        limit,
        offset,
        text,
        columnIndex,
        sortDirection,
        claimStatusList,
        policyStatusList,
        groupPolicyNoList,
        startDate,
        endDate
      },
      {
        responseType: "blob"
      }
    );
  }

  editPhoneNo(id: string, phone: string) {
    return this.http.post(`${this.basePathPolicy}EditPhoneNo/${id}`, {
      phone
    });
  }

  getPolicyBulkCancelLogListing(data: {
    limit: number;
    offset: number;
    text: string;
    columnIndex: number;
    sortDirection: string;
    isSuccess: boolean;
    startDate: Date;
    endDate: Date;
  }) {
    return this.http.post<{ count: number; data: any[] }>(
      `${this.basePathPolicy}GetPolicyBulkCancelLogListing`,
      data
    );
  }

  uploadPolicyBulkCancelExcel(data: File) {
    let formData: FormData = new FormData();
    formData.append("File", data);

    return this.http.post(
      `${this.basePathPolicy}UploadPolicyBulkCancelExcel`,
      formData,
      { responseType: "blob" }
    );
  }

  downloadPolicyBulkCancelExcelTemplate(): Observable<any> {
    return this.http.get<Blob>(
      `${this.basePathPolicy}DownloadPolicyBulkCancelExcelTemplate`,
      // @ts-ignore
      { responseType: "blob" }
    );
  }

  // End Shopee Policy

  getClaimStatus() {
    return ["New", "Processing", "Approved", "Rejected", "Disbursed"];
  }

  // Shopee Document
  downloadById(id: string) {
    return this.http.get(`${this.basePathDocument}DownloadById/${id}`, {
      responseType: "blob"
    });
  }
  // End Shopee Document

  // Maxis Policy
  getMaxisListing(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    startDate: Date,
    endDate: Date,
    claimStatusList?: string[],
    policyStatusList?: string[],
    groupPolicyNoList?: string[]
  ) {
    return this.http
      .post<{
        count: number;
        data: MaxisPoliciesListing[];
      }>(`${this.basePathMaxisPolicy}GetListing`, {
        limit,
        offset,
        text,
        columnIndex,
        sortDirection,
        claimStatusList,
        policyStatusList,
        groupPolicyNoList,
        startDate,
        endDate
      })
      .pipe(
        map((res) => ({
          count: res.count,
          data: res.data.map((pol) => ({
            ...pol,
            UI_claimStatus: getClaimStatusLabel(pol.claimStatus),
            UI_packageCode: getPackageCodeLabel(pol.packageCode)
          }))
        }))
      );
  }

  getMaxisPolicyClaimDetail(id: string): Observable<MaxisPolicyDetail> {
    return this.http.get<MaxisPolicyDetail>(
      `${this.basePathMaxisPolicy}GetPolicyClaimDetail/${id}`
    );
  }

  getMaxisPolicyListingExcel(
    limit: number,
    offset: number,
    text: string,
    columnIndex: number,
    sortDirection: string,
    claimStatusList: string[],
    policyStatusList: string[],
    groupPolicyNoList: string[],
    startDate: Date,
    endDate: Date
  ) {
    return this.http.post(
      `${this.basePathMaxisPolicy}GetPolicyListingExcel`,
      {
        limit,
        offset,
        text,
        columnIndex,
        sortDirection,
        claimStatusList,
        policyStatusList,
        groupPolicyNoList,
        startDate,
        endDate
      },
      {
        responseType: "blob"
      }
    );
  }

  getMaxisPolicyClaimListing(
    id: string,
    text: string,
    columnIndex: number,
    sortDirection: string,
    claimStatusList: string[],
    claimTypeList: string[]
  ) {
    return this.http
      .post<MaxisPolicyClaimListing[]>(
        `${this.basePathMaxisClaim}GetClaimListingByPolicyId`,
        {
          policyId: id,
          text,
          columnIndex,
          sortDirection,
          claimTypeList,
          claimStatusList
        }
      )
      .pipe(
        map((res) =>
          res.map((pol) => ({
            ...pol,
            UI_status: getClaimStatusLabel(pol.status)
          }))
        )
      );
  }

  getMaxisClaimTypeList(): CommonSelectionInterface[] {
    return [
      {
        id: "Accidental and Liquid Damage",
        name: "Accidental and Liquid Damage"
      },
      {
        id: "Accidental Damage",
        name: "Accidental Damage"
      },
      {
        id: "Screen Crack",
        name: "Screen Crack"
      },
      {
        id: "Attended Theft",
        name: "Attended Theft"
      }
    ];
  }

  getMaxisClaimStatusList(): CommonSelectionInterface[] {
    return [
      { id: "N", name: "New" },
      { id: "P", name: "Processing" },
      { id: "OR", name: "Ops Rejected" },
      { id: "PIA", name: "Pending Insurer Acceptance" },
      { id: "PIE", name: "Pending Insurer Evaluation" },
      { id: "IA", name: "Insurer Approved" },
      { id: "IR", name: "Insurer Rejected" },
      { id: "IW", name: "Insurer Withdrawn" },
      { id: "PTA", name: "Pending TPA Assessment" },
      { id: "PRR", name: "Pending Repair Replace" },
      { id: "C", name: "Completed" }
    ];
  }
  // End Maxis Policy
}
