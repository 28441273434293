import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MasterDataDataService } from "src/app/share/data-service/master-data.data.service";
import {
  MotorQuotationDataService,
  RejectQuoteRequest
} from "src/app/share/data-service/motor/motor-quotation.data.service";
import { RejectReasonDataService } from "src/app/share/data-service/reject-reason.data.service";
import { CommonSelectionInterface } from "src/app/share/interface/common.interface";
import { MotorQuotationRejectQuotation } from "src/app/share/interface/motor/motor-quotation.interface";
import { Subscription } from "rxjs";
import { DealerQuotationService } from "src/app/dealer/services/dealer-quotation.service";
import { Router } from "@angular/router";
import { SignalrService } from "src/app/services/signalr.service";

@Component({
  selector: "app-reject-quote",
  templateUrl: "./reject-quote.component.html",
  styleUrls: ["./reject-quote.component.scss"]
})
export class RejectQuoteComponent implements OnInit, OnDestroy {
  form: FormGroup;

  rejectTypeList: CommonSelectionInterface[] = [];
  rejectReasonList: string[] = [];
  rejectReasonChildList: string[] = [];
  showRemark: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private rejectReasonDataService: RejectReasonDataService,
    private masterDataService: MasterDataDataService,
    private motorQuotationDataService: MotorQuotationDataService,
    private dealerQuotationService: DealerQuotationService,
    public dialogRef: MatDialogRef<RejectQuoteComponent>,
    public signalRService: SignalrService,
    @Inject(MAT_DIALOG_DATA) private data: MotorQuotationRejectQuotation
  ) {
    this.initForm();
    this.listenChanges();
    this.signalRService.startConnection();
  }

  async ngOnInit(): Promise<void> {
    try {
      const response = await this.rejectReasonDataService.getRejectType();
      this.rejectTypeList = response.filter((obj) => !obj.hideForSelection);
    } catch (error) {}
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      rejectTypeId: [null, Validators.required],
      rejectReasonId: [null, Validators.required],
      remark: [null]
    });
  }

  listenChanges(): void {
    this.subscriptions.push(
      this.form.get("rejectTypeId").valueChanges.subscribe((value) => {
        this.getRejectReason(value);
        this.form.get("rejectReasonId").reset();
      }),

      this.form.get("rejectReasonId").valueChanges.subscribe(async (value) => {
        if (value === "Others") {
          this.showRemark = true;
        } else {
          this.showRemark = false;
        }

        if (value === "Invalid / Incorrect Info") {
          this.rejectReasonChildList =
            await this.masterDataService.getInvalidOrIncorrectInfoSelection();
        } else {
          this.rejectReasonChildList = [];
        }

        if (["Others", "Invalid / Incorrect Info"].includes(value)) {
          this.form.get("remark").setValidators(Validators.required);
        } else {
          this.form.get("remark").clearValidators();
        }

        this.form.get("remark").reset();
        this.form.get("remark").updateValueAndValidity();
      })
    );
  }

  private async getRejectReason(rejectType: string): Promise<void> {
    try {
      switch (rejectType) {
        // Rejected by PS
        case "e9f77c6f-6e43-46c9-935a-b69ac2814fbb":
          this.rejectReasonList =
            await this.masterDataService.getRejectReasonForRejectedByPSSelection();
          break;

        // Rejected by Insurer
        case "da850ed3-3368-4a82-bc6c-fa21d013787d":
          this.rejectReasonList =
            await this.masterDataService.getRejectReasonForRejectedByInsurerSelection();
          break;

        // Excluded
        case "3a61685c-5dd8-495b-9967-a3f1630c10ae":
          this.rejectReasonList =
            await this.masterDataService.getRejectReasonForExcludedFromQuoteSelection();
          break;
      }
    } catch (error) {
      this.rejectReasonList = [];
    }
  }

  async save(): Promise<void> {
    if (this.form.invalid) return;

    let value: FormData = this.form.value;

    let request: RejectQuoteRequest = {
      id: this.data.id,
      rejectReasonId: value.rejectTypeId,
      rejectRemark: value.rejectReasonId,
      comment: value.remark,
      isInstantQuotation: this.data.isInstantQuotation
    };

    try {
      const urlPrefix = this.router.url.split("/")[1];

      if (urlPrefix === "motor") {
        await this.motorQuotationDataService.reject(request);
      } else {
        await this.dealerQuotationService.rejectQuote(request);
        this.signalRService.sendQuotation('Reject');
      }

      this.dialogRef.close(true);
    } catch (error) {}
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }
}

interface FormData {
  rejectTypeId: string;
  rejectReasonId: string;
  remark: string;
}
