import { Component, Input, OnInit } from "@angular/core";
import { LoaderService } from "../../service/loader.service";

@Component({
  selector: "app-empty-record",
  templateUrl: "./empty-record.component.html",
  styleUrls: ["./empty-record.component.scss"]
})
export class EmptyRecordComponent {
  @Input() title: string = "No Record Found";

  constructor(public loaderService: LoaderService) {}

  _disableImage: boolean;
  get disableImage(): boolean {
    return this._disableImage;
  }
  @Input() set disableImage(value: boolean) {
    this._disableImage = "" + value !== "false";
  }

  _disableSubTitle: boolean;
  get disableSubTitle(): boolean {
    return this._disableSubTitle;
  }
  @Input() set disableSubTitle(value: boolean) {
    this._disableSubTitle = "" + value !== "false";
  }
}
