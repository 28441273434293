import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../share/service/auth.service";
import { UserDataService } from "src/app/share/data-service/user.data.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html"
})
export class LoginComponent {
  formGroup: FormGroup;
  hide: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userDataService: UserDataService
  ) {
    this.formGroup = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });

    if (authService.isLoggedIn()) {
      location.href = "/motor/quotations";
    }
  }

  async login(): Promise<void> {
    if (this.formGroup.invalid) return;

    let value: FormData = this.formGroup.value;

    try {
      const loginResponse = await this.userDataService.login(
        value.email,
        value.password
      );

      this.authService.setToken(loginResponse);

      const accessRights = await this.userDataService.getUserAccessRights();
      const [productClaims, screenClaims, functionClaims] =
        Object.values(accessRights);

      localStorage.setItem("getTokenProductClaims", productClaims);
      localStorage.setItem("getTokenScreenClaims", screenClaims);
      localStorage.setItem("getTokenFunctionClaims", functionClaims);

      location.href = "/settings/myaccount";
    } catch (error) {}
  }
}

interface FormData {
  email: string;
  password: string;
}
