<mat-toolbar class="page-header">
  <div class="menu-container">
    <button mat-icon-button class="pg-header-btn" backPage>
      <mat-icon>chevron_left</mat-icon>
    </button>
    <div class="pg-title">Rider Accident Details</div>
    <span fxFlex></span>
  </div>
</mat-toolbar>

<div class="content-container md" *ngIf="policyData">
  <div class="sub-header-container">
    <div class="sub-header-wrapper">
      <ng-container [ngSwitch]="policyData.status" style="margin-bottom: 8px">
        <span class="cover-label grey lg" *ngSwitchCase="'Inactive'">{{
          policyData.status
        }}</span>
        <span class="cover-label lg" *ngSwitchCase="'Active'">{{
          policyData.status
        }}</span>
        <span class="cover-label red lg" *ngSwitchCase="'Expired'">{{
          policyData.status
        }}</span>
      </ng-container>
    </div>
    <div class="button-row">
      <button
        appCheckFunctionPermission
        [functionCode]="'f_riderprotection_' + partnerCode + '_documents'"
        mat-raised-button
        [matMenuTriggerFor]="documents"
        (click)="getDocuments()"
      >
        Documents
      </button>
      <mat-menu #documents="matMenu">
        <ng-container *ngIf="documentList?.length; else noDocuments">
          <button
            mat-menu-item
            *ngFor="let doc of documentList"
            (click)="download(doc)"
          >
            {{ getDocumentName(doc.refType) }}
          </button>
        </ng-container>
        <ng-template #noDocuments>
          <button mat-menu-item [disabled]="true">
            No documents available
          </button>
        </ng-template>
      </mat-menu>
      <button
        appCheckFunctionPermission
        [functionCode]="
          'f_riderprotection_' + partnerCode + '_activatedeactivateriderpolicy'
        "
        mat-raised-button
        (click)="togglePolicy()"
        *ngIf="policyData.status !== 'Expired'"
      >
        {{
          policyData.status === "Active"
            ? "Deactivate Rider Policy"
            : "Activate Rider Policy"
        }}
      </button>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="16px grid" class="full-width">
    <div fxFlex>
      <div fxLayout="column" fxLayoutGap="0px grid">
        <div fxFlex="50">
          <div #personalInfoDiv>
            <mat-card class="card-details">
              <div class="card-title-container-2">
                <mat-icon color="primary" class="card-title-icon"
                  >person</mat-icon
                >
                <div>
                  <div class="title-f" [copyValue]="riderData.name">
                    {{ riderData.name }}
                  </div>
                  <div class="icon-text-wrapper">
                    <div class="icon-text">
                      <mat-icon>call</mat-icon>
                      <span [copyValue]="riderData.mobileNo">{{
                        riderData.mobileNo
                      }}</span>
                    </div>
                    <div class="icon-text">
                      <mat-icon>email</mat-icon>
                      <span [copyValue]="riderData.email">{{
                        riderData.email
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="row-content">
                <div>
                  <span class="label-f"> IC No. </span>
                  <span class="value-f" [copyValue]="riderData.identityNo">
                    {{ riderData.identityNo }}
                  </span>
                </div>
                <ng-container>
                  <div>
                    <span class="label-f"> DOB </span>
                    <span
                      class="value-f"
                      [copyValue]="riderData.dob | date : 'dd/MM/yyyy'"
                    >
                      {{ riderData.dob | date : "dd MMM yyyy" }}
                    </span>
                  </div>

                  <div>
                    <span class="label-f">Gender </span>
                    <div [ngSwitch]="riderData.gender">
                      <span
                        *ngSwitchCase="'M'"
                        class="value-f"
                        [copyValue]="'male'"
                      >
                        <mat-icon class="color-blue gender-icon">male</mat-icon
                        >Male</span
                      >
                      <span
                        *ngSwitchDefault
                        class="value-f"
                        [copyValue]="'female'"
                      >
                        <mat-icon class="color-red gender-icon">female</mat-icon
                        >Female</span
                      >
                    </div>
                  </div>
                  <div>
                    <span class="label-f"> Marital Status </span>
                    <span class="value-f" [copyValue]="riderData.maritalStatus">
                      {{ riderData.maritalStatus }}
                    </span>
                  </div>
                </ng-container>
              </div>
            </mat-card>
          </div>

          <div #policyInfoDiv>
            <mat-card class="card-details">
              <div class="card-title-container-2">
                <div style="width: 40px; height: 40px; margin-right: 16px">
                  <img
                    [src]="policyData.productImg"
                    style="height: 100%; width: 100%; object-fit: cover"
                  />
                </div>
                <div class="title-f">
                  {{ policyData.productName }}
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="row-content">
                <div>
                  <span class="label-f"> Product ID </span>
                  <span class="value-f" [copyValue]="policyData.insuranceNo">
                    {{ policyData.insuranceNo }}
                  </span>
                </div>
                <div>
                  <span class="label-f"> Partner </span>
                  <span class="value-f" [copyValue]="policyData.partner">
                    {{ policyData.partner }}
                  </span>
                </div>
                <div>
                  <span class="label-f"> Start date </span>
                  <span class="value-f" [copyValue]="policyData.startDate">
                    {{ policyData.startDate | date : "dd/MM/yyyy" }}
                  </span>
                </div>
                <div>
                  <span class="label-f"> End date </span>
                  <span class="value-f" [copyValue]="policyData.endDate">
                    {{ policyData.endDate | date : "dd/MM/yyyy" }}
                  </span>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div #coverageInfoDiv>
          <mat-card class="card-details">
            <div class="card-title-container-2">
              <mat-icon color="primary" class="card-title-icon"
                >local_police</mat-icon
              >
              <div class="title-f">Coverage Summary</div>
            </div>
            <mat-divider></mat-divider>
            <div class="row-content">
              <div *ngFor="let coverage of coverageList">
                <span class="label-f"> {{ coverage.type }} </span>
                <span class="value-f">
                  {{
                    convertCoverageSummaryValue(coverage.value, coverage.key)
                  }}
                </span>
              </div>
            </div>

            <mat-divider></mat-divider>
          </mat-card>
        </div>

        <div #claimsDiv>
          <mat-card class="card-details" style="padding: 16px 0 0 0 !important">
            <div
              class="card-title-container-2"
              style="
                display: flex;
                justify-content: space-between;
                padding: 8px 16px 16px 16px !important;
              "
            >
              <div style="display: flex">
                <mat-icon color="primary" class="card-title-icon"
                  >paid</mat-icon
                >

                <div class="title-f">Claims</div>
              </div>
              <div>
                <mat-form-field
                  appearance="outline"
                  floatLabel="never"
                  class="pg-filter"
                >
                  <mat-select [(ngModel)]="claimStatus">
                    <mat-option value="All" (click)="filterClaimHistory()">
                      All
                    </mat-option>

                    <mat-option value="New" (click)="filterClaimHistory()">
                      New
                    </mat-option>

                    <mat-option
                      value="Processing PS"
                      (click)="filterClaimHistory()"
                    >
                      Processing PS
                    </mat-option>

                    <mat-option
                      value="Processing Insurer"
                      (click)="filterClaimHistory()"
                    >
                      Processing Insurer
                    </mat-option>

                    <mat-option value="Approved" (click)="filterClaimHistory()">
                      Approved
                    </mat-option>

                    <mat-option value="Rejected" (click)="filterClaimHistory()">
                      Rejected
                    </mat-option>

                    <mat-option
                      value="Disbursed"
                      (click)="filterClaimHistory()"
                    >
                      Disbursed
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  floatLabel="never"
                  class="pg-filter"
                >
                  <input
                    matInput
                    placeholder="Search reference no..."
                    autocomplete="off"
                    [(ngModel)]="claimsSearchText"
                    (ngModelChange)="searchClaimsHistoryChanged()"
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    class="clear-field-btn"
                    (click)="resetClaimsSearchFilter()"
                    *ngIf="claimsSearchText"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>
            <div>
              <mat-card class="card-table">
                <table
                  mat-table
                  [dataSource]="claimPaginationList"
                  matSort
                  matSortActive="date"
                  matSortDisableClear
                  matSortDirection="desc"
                >
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Date
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.createdDate | date : "dd/MM/yyyy" }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="referenceNo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Reference No.
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      [copyValue]="element.refNo"
                    >
                      {{ element.refNo }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="insuranceType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Insurance Type
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div>
                        {{ element.type }}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Status
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <span
                        class="cover-label grey"
                        *ngIf="element.status === 'New'"
                        >New</span
                      >
                      <span
                        class="cover-label blue"
                        *ngIf="element.status === 'Approved'"
                        >Approved</span
                      >
                      <span
                        class="cover-label red"
                        *ngIf="element.status === 'Rejected'"
                        >Rejected</span
                      >
                      <span
                        class="cover-label green"
                        *ngIf="element.status === 'Disbursed'"
                        >Disbursed</span
                      >

                      <span
                        class="cover-label orange"
                        *ngIf="
                          element.status === 'Processing PS' ||
                          element.status === 'Processing Insurer'
                        "
                        >{{ element.status }}</span
                      >
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                      <button
                        appCheckFunctionPermission
                        [functionCode]="
                          'f_claims_riderprotection' + partnerCode + '_view'
                        "
                        mat-icon-button
                        (click)="viewClaim(element)"
                      >
                        <mat-icon>visibility</mat-icon>
                      </button>
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="claimsDisplayedColumns"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: claimsDisplayedColumns"
                  ></tr>
                  <tr *matNoDataRow>
                    <td class="mat-cell" colspan="9999">
                      <app-empty-record
                        *ngIf="claimList.length === 0"
                        [disableSubTitle]="true"
                      ></app-empty-record>
                      <app-skeleton-loader></app-skeleton-loader>
                    </td>
                  </tr>
                </table>
                <mat-paginator
                  [pageSizeOptions]="[10, 20, 30]"
                  [pageSize]="limit"
                  [length]="length"
                  (page)="pageChange($event)"
                  aria-label="Select page of users"
                ></mat-paginator>
                <mat-divider></mat-divider>
              </mat-card>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
