import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { firstValueFrom, Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class MasterDataDataService {
  private basePath: string = `${environment.url}/api/MasterData/`;

  constructor(private http: HttpClient) {}

  getSelection() {
    return this.http.get<string[]>(`${this.basePath}GetCountrySelection`);
  }

  getInsuranceProviderSelection() {
    return this.http.get<{ id: string; name: string }[]>(
      `${this.basePath}GetInsuranceProviderSelection`
    );
  }

  getEmployeeCompanyStatusSelection() {
    return this.http.get<string[]>(
      `${this.basePath}GetEmployeeCompanyStatusSelection`
    );
  }

  getFileDocConditionList() {
    return this.http.get(`${this.basePath}GetFileDocConditionList`);
  }

  getShopeeClaimTypeSelection() {
    return this.http.get(`${this.basePath}GetShopeeClaimTypeSelection`);
  }

  getRejectReasonSelection() {
    return this.http.get(`${this.basePath}GetRejectReasonSelection`);
  }

  async getRejectReasonForRejectedByPSSelection(): Promise<string[]> {
    let response: string[];

    response = await firstValueFrom(
      this.http.get<string[]>(
        `${this.basePath}GetRejectReasonForRejectedByPSSelection`
      )
    );

    return response;
  }

  async getInvalidOrIncorrectInfoSelection(): Promise<string[]> {
    let response: string[];

    response = await firstValueFrom(
      this.http.get<string[]>(
        `${this.basePath}GetInvalidOrIncorrectInfoSelection`
      )
    );

    return response;
  }

  async getRejectReasonForRejectedByInsurerSelection(): Promise<string[]> {
    let response: string[];

    response = await firstValueFrom(
      this.http.get<string[]>(
        `${this.basePath}getRejectReasonForRejectedByInsurerSelection`
      )
    );

    return response;
  }

  async getRejectReasonForExcludedFromQuoteSelection(): Promise<string[]> {
    let response: string[];

    response = await firstValueFrom(
      this.http.get<string[]>(
        `${this.basePath}GetRejectReasonforExcludedFromQuoteSelection`
      )
    );

    return response;
  }

  getEwpClaimRejectReasonSelection() {
    return this.http.get(`${this.basePath}GetEwpClaimRejectReasonSelection`);
  }

  getEwpClaimStatusCashSelection() {
    return this.http.get(`${this.basePath}GetEwpClaimStatusCashSelection`);
  }

  getEwpClaimStatusRepairAndReplaceSelection() {
    return this.http.get(
      `${this.basePath}GetEwpClaimStatusRepairAndReplaceSelection`
    );
  }

  getEWPStatusSelection() {
    return this.http.get(`${this.basePath}GetEwpStatusSelection`);
  }

  getEWPClaimRejectReasonSelection() {
    return this.http.get(`${this.basePath}GetEWPClaimRejectReasonSelection`);
  }

  getEWPClaimStatusCashSelection() {
    return this.http.get(`${this.basePath}GetEWPClaimStatusCashSelection`);
  }

  getEWPClaimStatusRepairAndReplaceSelection() {
    return this.http.get(
      `${this.basePath}GetEWPClaimStatusRepairAndReplaceSelection`
    );
  }

  getEWPolicyCancellationReasonSelection() {
    return this.http.get(
      `${this.basePath}GetEWPolicyCancellationReasonSelection`
    );
  }

  getMWCerificationTypeSelection() {
    return this.http.get(`${this.basePath}GetMWCerificationTypeSelection`);
  }

  getPSRoleSelection() {
    return this.http.get(`${this.basePath}GetPSRoleSelection`);
  }

  getBulkQuotationStatusSelection(): Observable<any> {
    return this.http.get<string[]>(
      `${this.basePath}GetBulkQuotationStatusSelection`
    );
  }

  getPortalSelection() {
    return this.http.get(`${this.basePath}GetPortalSelection`);
  }
}
