import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { environment } from "../../environments/environment";

export interface QuotationInterface {
  basePremium: number;
  code: string;
  id: string;
  image: string;
  maxWindshieldPrice: number;
  mileagePlanDiscount: number;
  minWindshieldPrice: number;
  name: string;
  platformDiscountRate: number;
  productId: string;
  rebate?: number;
  serviceTaxRate: number;
  stampDutyPrice: number;
  status?: string;
  sumInsured: number;
  taxRate: number;
  total: number;
  totalBasePremium: number;
  typeOfSum?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
   private basePath: string = `${environment.url}`;

  private hubConnection: signalR.HubConnection
    public startConnection = () => {
      this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)  // add this for diagnostic clues
      .withUrl(this.basePath + "/hub", { 
        skipNegotiation: true,  // skipNegotiation as we specify WebSockets
        transport: signalR.HttpTransportType.WebSockets, // force WebSocket transport
      })
      .build();

      this.hubConnection
        .start()
        .then(() => console.log('Connection started'))
        .catch(err => console.log('Error while starting connection: ' + err))
    }

    sendQuotation(message: string): void {
      this.hubConnection.invoke('SendQuotation', message);
    }

    public stopConnection() {
      this.hubConnection
      .stop()
      .then(() => console.log('Connection stopped'))
      .catch(err => console.log('Error while stopping connection: ' + err))
    }
    
}
