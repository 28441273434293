import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import * as moment from "moment";
import { MotorInsuredPolicyDataService } from "src/app/share/data-service/motor/motor-insured-policy.data.service";

@Component({
  selector: "app-declaration-history-list",
  templateUrl: "./declaration-history-list.component.html",
  styleUrls: ["./declaration-history-list.component.scss"]
})
export class DeclarationHistoryListComponent {
  list: any[] = [
    {
      startDate: new Date().setDate(1),
      endDate: new Date().setDate(6)
    },
    {
      startDate: new Date().setDate(9),
      endDate: new Date().setDate(12)
    }
  ];
  displayedColumns: string[] = ["period"];
  riderData: {
    id: string;
    name: string;
    identityNo: string;
    startDate: Date;
    endDate: Date;
  };

  sortIndex: number = 0;
  sortDirection: "asc" | "desc" = "asc";

  startDate: Date;
  endDate: Date;

  minFilterEndDate: Date;
  filterSelected: "month" | "custom" = "month";
  lastFilterSelected: "month" | "custom" = "month";

  constructor(
    public dialogRef: MatDialogRef<DeclarationHistoryListComponent>,
    private insuredPolicyDataService: MotorInsuredPolicyDataService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      id: string;
      name: string;
      identityNo: string;
      startDate: Date;
      endDate: Date;
    }
  ) {
    this.riderData = data;
    this.setCurrentMonthDate();
  }

  private getData() {
    this.insuredPolicyDataService
      .getClaimDeclarationHistory(
        this.riderData.id,
        this.startDate,
        this.endDate
      )
      .subscribe({
        next: (x: {
          declarationHistory: any[];
          startDate: string;
          endDate: string;
        }) => {
          this.list = x.declarationHistory;
        },
        error: (err) => {
          console.log(err);
        }
      });
    this.list = [];
  }

  filter() {
    this.getData();
  }

  resetDate() {
    this.setCurrentMonthDate();
  }

  customDateChange(e: Date) {
    this.startDate = e;
    this.endDate = moment(e).endOf("day").toDate();
    this.filter();
  }

  sortChange(sortState: Sort) {
    this.sortDirection = null;
    this.sortIndex = -1;
    if (sortState.direction) {
      this.sortDirection = sortState.direction;
      this.sortIndex = this.displayedColumns.findIndex(
        (x) => x == sortState.active
      );
    }
    if (this.list.length == 0) return;

    this.getData();
  }

  private setDefaultTimeRange() {
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 999);
  }

  resetDateFilter() {
    event.stopImmediatePropagation();
    this.resetDate();
    this.filterSelected = "month";
    this.lastFilterSelected = this.filterSelected;
  }

  setCurrentMonthDate() {
    this.startDate = moment().startOf("month").toDate();
    this.endDate = moment().endOf("month").toDate();
    this.setDefaultTimeRange();
    this.filter();
  }

  setPast6MonthsDate() {
    this.startDate = moment().startOf("month").subtract(6, "months").toDate();
    this.endDate = moment().endOf("month").toDate();
    this.setDefaultTimeRange();
    this.filter();
  }
}
